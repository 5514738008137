import * as React from "react"
import SEO from "components/seo"
import { PageContextT, SupportArticleT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Container from "../components/atoms/Container"
import BlockTitle from "../components/atoms/BlockTitle"
import styled from "styled-components"
import { PageProps } from "gatsby"
import WysiwygRenderer from "components/atoms/WysiwygRenderer"
import { formatDate } from "utilities/dateTime"
import SupportContact from "components/atoms/SupportContact"
import SupportNavigationSidebar from "components/atoms/SupportNavigationSidebar"
import { useTranslation } from "react-i18next"

const Title = styled(BlockTitle)`
  margin-bottom: 0.2em;
`
type PropsT = PageContextT<SupportArticleT>

const SupportDetail: React.FC<PageProps<null, PropsT, null>> = props => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      configurations,
      mobileHeaderLinks,
    },
  } = props
  const { t, i18n } = useTranslation()
  const breadcrumbs = [
    {
      title: t("support"),
      route: i18n.language === "en" ? "/en/support" : "/podpora",
    },
    {
      title: t("services"),
      route:
        i18n.language === "en" ? "/en/support/services" : "/podpora/sluzby",
    },
    {
      title: page.title,
    },
  ]
  let sectionRefs = {}

  page.sections.map(section => {
    sectionRefs[section.id] = React.useRef(null)
  })
  sectionRefs[0] = React.useRef(null)

  const executeScroll = ref => sectionRefs[ref].current.scrollIntoView()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      breadcrumbs={breadcrumbs}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={["/podpora", "/en/support"]}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />
      <Container>
        <div className="flex w-full flex-wrap sm:mt-16 mt-8">
          <div className="w-full md:w-4/12 pl-4 pr-0 sm:pl-0 sm:pr-0 mb-4 sm:block hidden">
            <SupportNavigationSidebar
              executeScroll={executeScroll}
              sections={page.sections}
              isSticky={true}
              visibleOnMobile={false}
            />
          </div>
          <div
            className="w-full md:w-8/12 sm:pr-0 pr-2 pl-2 sm:px-8"
            ref={sectionRefs[0]}
          >
            <Title title={page.name} align={"left"} />
            <div className={"sm:mb-8 mb-4"}>
              {t("lastChange")} {formatDate(page.updated_at)}
            </div>
            <SupportNavigationSidebar
              executeScroll={executeScroll}
              sections={page.sections}
              isSticky={false}
              visibleOnMobile={true}
            />
            <WysiwygRenderer content={page.perex} />

            {page.sections.map(section => {
              return (
                <div key={section.id} ref={sectionRefs[section.id]}>
                  <WysiwygRenderer content={"<h2>" + section.title + "</h2>"} />
                  <WysiwygRenderer content={section.text} />
                </div>
              )
            })}
          </div>
        </div>
        <div className={"mb-8"}>
          <SupportContact
            backButtonLink={i18n.language === "en" ? "/en/support" : "/podpora"}
            backButtonTitle={t("backToSupport")}
            relatedArticles={page.related_articles}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default SupportDetail
